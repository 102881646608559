import React, { useState, useRef, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Rodal from 'rodal';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { userAuth } from '../../../contexts/userContext';
import './Login.css';
import Icon from '../../../components/icon/Icon';
// include styles
import 'rodal/lib/rodal.css';
import { Ralert } from '../../common/vhelper/Normarodallalert';
import Loader from '../../common/vhelper/Loader';
import { useTranslation } from 'react-i18next';
import LANG, { getLangWithKey } from '../../../lang';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h3 fw-bold mt-2'>Create Account,</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-3'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { i18n } = useTranslation();

	const { darkModeStatus, themeStatus } = useDarkMode();

	const [isNewUser] = useState(isSignUp);

	const emailRef = useRef();
	const usrnamelRef = useRef();
	const passwordRef = useRef();

	const [isempty2, setIsempty2] = useState(false);
	const [loadalert, setLoadalert] = useState(false);
	const [msgloadalert, setMsgLoadalert] = useState('');
	const [msgtypeloadalert, setMsgtypeLoadalert] = useState('');

	const [loaderv, setLoaderv] = useState(true);

	const navigate = useNavigate();

	const lemailRef = useRef();
	const lpasswordRef = useRef();

	const { token, setToken } = useContext(userAuth);

	// reg pw show
	const [showPassword, setShowPassword] = useState(false);
	const [isemptyp, setIsemptyp] = useState({ error: false, msg: '*Required' });
	const [isemptym, setIsemptym] = useState({ error: false, msg: '*Required' });

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const closemodel = () => {
		setLoadalert(false);
	};
	const startmodel = () => {
		setLoadalert(true);
	};
	const closemodel2 = () => {
		setIsempty2(false);
	};
	const startmodel2 = () => {
		setIsempty2(true);
	};

	useEffect(() => {
		const currentLang = getLangWithKey(i18n.language);

		if (!currentLang) {
			i18n.changeLanguage(LANG.EN.lng);
		}
	}, []);

	const login = async () => {
		let cansendapi = true;
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!lemailRef.current.value) {
			setIsemptym({ error: true, msg: '*Required' });
			cansendapi = false;
		} else if (emailRegex.test(`${lemailRef.current.value}`.trim())) {
			setIsemptym({ error: false });
		} else {
			setIsemptym({ error: true, msg: 'Please include an @ in the email address.' });
			cansendapi = false;
		}
		if (!lpasswordRef.current.value) {
			setIsemptyp({ error: true, msg: '*Required' });
			cansendapi = false;
		} else {
			setIsemptyp({ error: false });
		}

		if (cansendapi) {
			const item = {
				action: 'login',
				email: lemailRef.current.value,
				password: lpasswordRef.current.value,
			};
			const weblink = process.env.REACT_APP_WEB_LINK;
			try {
				const res = await fetch(weblink, {
					// const res = await fetch(`${weblink}?email=${lemailRef.current.value}&password=${lpasswordRef.current.value}`, {
					// https://webapi.schedulerdesk.com/webapi/employee/w1/login?email=''&password
					// const res = await fetch(`https://webapi.schedulerdesk.com/webapi/employee/w1/login?email=${lemailRef.current.value}&password=${lpasswordRef.current.value}`, {

					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
					body: JSON.stringify(item),
				});
				const result = await res.json();

				if (result.type === 'Success' || result.type === 'success') {
					if (result.data.userrole === 'emp') {
						setToken(result.data);
						localStorage.setItem('user-auth', JSON.stringify(result.data));
						navigate('dashboard-booking');
						console.log('result data', result);
					} else {
						startmodel();
						setMsgLoadalert(result.message);
						setMsgtypeLoadalert(result.type);
					}
				} else {
					startmodel();
					setMsgLoadalert(result.message);
					setMsgtypeLoadalert(result.type);
				}
			} catch (error) {
				//startmodel2();
				console.log('error', error);
			}
		}
	};

	useEffect(() => {
		setLoaderv(true);
		const authtimer = setTimeout(() => {
			if (token.email) {
				navigate('dashboard-booking');
				// navigate('/landing/dashboard');
			} else {
				setLoaderv(false);
			}
		}, 300);
		return () => {
			clearTimeout(authtimer);
		};
	}, [token.email]);
	if (loaderv) {
		return <Loader />;
	}

	return (
		<>
			<Ralert
				msgloadalert={msgloadalert}
				msgtypeloadalert={msgtypeloadalert}
				loadalert={loadalert}
				closemodel={closemodel}
			/>

			<div className='bg-img'>
				<PageWrapper title={isNewUser ? 'Sign Up' : 'Login'}>
					<Page
						className='p-0 mt-3'
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						{/* <div className='row h-100 align-items-center justify-content-center'>
							<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'> */}
						<br />
						<br />
						<br />
						<Card
							className='shadow-3d-dark mt-1'
							data-tour='login-page'
							style={{
								maxWidth: '600px',
								maxHeight: '545px',
							}}>
							<CardBody>
								<div className='text-center my-3'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className=''>
										<div className='col'>
											{/* <Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={!!isNewUser}
													className='rounded-1 w-100'
													size='lg'
													onClick={() => {
														setUsernameInput(false);
														setIsNewUser(!isNewUser);
													}}>
													Login
												</Button> */}
										</div>
										{/* <div className='col'>
												<Button
													color={darkModeStatus ? 'light' : 'dark'}
													isLight={!isNewUser}
													className='rounded-1 w-100'
													size='lg'
													onClick={() => {
														setUsernameInput(false);
														setIsNewUser(!isNewUser);
													}}>
													Sign Up
												</Button>
											</div> */}
									</div>
								</div>

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									{isNewUser ? (
										<div className='mt-5'>
											<div className='col-12'>
												<FormGroup isFloating label='Your email'>
													<Input type='email' ref={emailRef} />
												</FormGroup>
											</div>
											<div className='col-12 mt-4'>
												<FormGroup isFloating label='Your name'>
													<Input type='text' ref={usrnamelRef} />
												</FormGroup>
											</div>
											<div className='col-12 mt-3'>
												<FormGroup isFloating label='Password'>
													<Input type='password' ref={passwordRef} />
												</FormGroup>
											</div>
											<div className='col-12 mt-3'>
												<Button
													color='info'
													className='w-100 py-3'
													// onClick={() => signUpp()}
												>
													Sign Up
												</Button>
											</div>
										</div>
									) : (
										<>
											<div className='col-12 mt-4'>
												<FormGroup
													id='lemail'
													isFloating
													label='Your Email or Username'>
													<Input autoComplete='email' ref={lemailRef} />
												</FormGroup>
												{isemptym.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptym.msg ? isemptym.msg : '*Required'}
													</p>
												) : (
													<br />
												)}

												<FormGroup isFloating label='Password'>
													<Input
														type={showPassword ? 'text' : 'password'}
														autoComplete='password'
														ref={lpasswordRef}
													/>
													<button
														type='button'
														onClick={togglePasswordVisibility}
														style={{
															position: 'absolute',
															right: '10px', // Adjust the right position as needed
															top: '50%',
															transform: 'translateY(-50%)',
															border: 'none',
															background: 'none',
															cursor: 'pointer',
														}}>
														{showPassword ? (
															<Icon
																style={{
																	color:
																		themeStatus === 'dark'
																			? 'white'
																			: 'black',
																}}
																icon='Visibility'
																size='2x'
															/>
														) : (
															<Icon
																style={{
																	color:
																		themeStatus === 'dark'
																			? 'white'
																			: 'black',
																}}
																icon='VisibilityOff'
																size='2x'
															/>
														)}{' '}
													</button>
												</FormGroup>
												{isemptyp.error ? (
													<p
														style={{
															color: 'red',
															fontSize: '12px',
														}}>
														{' '}
														{isemptyp.msg ? isemptyp.msg : '*Required'}
													</p>
												) : (
													<br />
												)}
												<a
													href='landing/forgot'
													className={classNames(
														'text-decoration-none me-3',
														{
															'link-light': darkModeStatus,
															white: !darkModeStatus,
														},
													)}
													style={{
														marginLeft: '8px',
														fontSize: 13,
													}}>
													Forgotten password?
												</a>
											</div>
											<div className='col-12 mb-5'>
												<Button
													color='success'
													className='w-100 py-3'
													onClick={() => login()}>
													Login
												</Button>
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						{/* <div
									className=''
									style={{ 
										// marginLeft: '240px', marginTop: '8px' 
										display:'flex',
											flexDirection:'row',
											alignItems:'center'
										}}>
									<a
										href='landing/privacy'
										className={classNames('text-decoration-none me-3', {
											'link-light': isNewUser,
											'link-dark': !isNewUser,
										})}
										style={{ color: 'white' }}>
										Privacy policy
									</a>
									<a
										href='landing/terms'
										className={classNames('link-light text-decoration-none', {
											'link-light': isNewUser,
											'link-dark': !isNewUser,
										})}
										style={{ color: 'white' }}>
										Terms of use
									</a>
								</div> */}

						{/* </div>
						</div> */}
					</Page>
				</PageWrapper>
			</div>
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
